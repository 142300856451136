import React, { useState } from 'react';

import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCorrectUserInput, setCorrectUserInput] = useState(false);


  const { username, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    if (checkUserInput(contact)) {
        setLoading(true);
        client.create(contact)
          .then(() => {
            setLoading(false);
            setIsFormSubmitted(true);
          })
          .catch((err) => console.log(err));
        }

  }

  const checkUserInput = (contact) => {
      console.log(contact.name,contact.email,contact.message)
      if(!(contact.name === "" || contact.name === null || contact.email === "" ||contact.email === null
        || contact.message === "" || contact.message === null )) {
        return true;
      }
  }

  return (
    <>
      <h2 className="head-text">Contact Me</h2>

      <div className="app__footer-cards">
        <a href="mailto:dagasannuri@gmail.com" className="p-text">
          <div className="app__footer-card ">
            <img src={images.email} alt="email" />
            <p>dagasannuri@gmail.com</p>
          </div>
        </a>
        <a href="https://my-resume-2022.s3.eu-west-2.amazonaws.com/NuriDagasan_Resume.pdf" target="_blank" rel="noopener noreferrer" className="p-text">
          <div className="app__footer-card">
            <img src={images.resume} alt="phone" />
            <p>My Resume</p>
          </div>
        </a>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" minlength="3" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" pattern=".+@\." size="30" value={email} onChange={handleChangeInput} />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you for your message!
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);