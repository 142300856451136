import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { FaMedium } from 'react-icons/fa';
import { GoMarkGithub } from "react-icons/go";


const SocialMedia = () => (
  <div className="app__social">
    <div>
    <a href="https://github.com/nuridagasan" target="_blank" rel="noopener noreferrer"><GoMarkGithub/></a>
    </div>
    <div>
    <a href="https://www.linkedin.com/in/dagasannuri/" target="_blank" rel="noopener noreferrer"><BsLinkedin/></a>
    </div>
    <div>
    <a href="https://dagasannuri.medium.com/" target="_blank" rel="noopener noreferrer"><FaMedium/></a>   
    </div>
  </div>
);

export default SocialMedia;
