import React, { useState } from 'react';
import {HiMenu, HiX} from 'react-icons/hi';
import {motion} from 'framer-motion';
import {images} from '../../constants';
import './Navbar.scss';

const Navbar = () => {
  const [toggle,setToggle] = useState(false);

  return (
    <nav className="app__navbar">
        <div className="app__navbar-logo">
            <img src={images.logo} alt="logo"/>
        </div>
 
        <ul className="app__navbar-links">
          {
          ['home','about','work','skills','contact']
          .map((section => 
          <li className="app__flex p-text" key={`link-${section}`}>
            <div/>
            <a className="app__navbar-links" href={`#${section}`}>{section}</a>
          </li>
          ))}
          <li className="app__flex p-text">
            <div/>
            <a className="app__navbar-links" href="https://dagasannuri.medium.com/" target="_blank" rel="noopener noreferrer">blog</a>
          </li>
        </ul>
        
        <div className="app__navbar-menu">
        <HiMenu onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'about', 'work', 'skills', 'contact'].map((section) => (
                <li key={section}>
                  <a href={`#${section}`} onClick={() => setToggle(false)}>
                    {section}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>

    </nav>
  )
}

export default Navbar